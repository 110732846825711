const Constants = {
    FEATURES: {
        TYPE_COLOR: 'color',
        TYPE_COLOR_DETAIL: 'accent-color' // todo, prüfen, ob hier Verwechslung des Bezeichners vorliegt - 'color-detail'
    },

    FADE_IN: true,
    ADD_INSTANT: false,

    // ACHTUNG: Immer mit _media-queries.scss synchronisieren!
    // css-Breakpoints + MediaQueries
    SCREEN_SMALL: 0,
    SCREEN_MEDIUM: 768,
    SCREEN_LARGE: 1024,

    //Browser Support
    BROWSERS: [
        {
            NAME: 'Chrome',
            VERSION_REGEX: /Chrome\/([\d.]+)/,
            MIN_VERSION: 80,
            EXCLUDE: /Edg/      //Exlude is used to distinguish betwwen Chrome and Chromium-based Browsers like MS Edge
        },
        {
            NAME: 'Firefox',
            VERSION_REGEX: /Firefox\/([\d.]+)/,
            MIN_VERSION: 80
        },
        {
            NAME: 'Edge',
            VERSION_REGEX: /Edg\/([\d.]+)/,
            MIN_VERSION: 80
        },
        {
            NAME: 'Safari',
            VERSION_REGEX: /Version\/([\d.]+)/,
            MIN_VERSION: 14.1,
            EXCLUDE: /Chrome/
        },
        {
            NAME: 'OPR',
            VERSION_REGEX: /OPR\/([\d.]+)/,
            MIN_VERSION: 67
        }
    ],

    // MediaQueries
    MQ: {
        SMALL_ONLY: 0,
        MEDIUM_ONLY: 1,
        LARGE_ONLY: 2
    },

    ORIENTATION_PORTRAIT: 'Constants.orientationPortrait',
    ORIENTATION_LANDSCAPE: 'Constants.orientationLandscape',

    /**
     * Durations und easings analog zu den scss-Variablen.
     * Bitte nur diese verwenden (evtl. erweitern), um ein einheitliches Motion-Konzept zu erhalten.
     */
    DURATION_250: 0.25,
    DURATION_350: 0.35,
    DURATION_500: 0.50,
    DURATION_750: 0.75,
    DURATION_1000: 1.0,

    EASE_IN: Cubic.easeIn,
    EASE_OUT: Cubic.easeOut,
    EASE_IN_OUT: Cubic.easeInOut,


    // GAPS / Abstände analog scss
    GAP_1: [10, 10, 10],
    GAP_2: [20, 20, 20],
    GAP_3: [30, 30, 40],
    GAP_4: [55, 70, 100],


    COOKIES: {
        CITY: 'rm.city',
        LATLONG: 'rm.latlong',
        PRECISION_LAYER: 'r-m.precision-layer',
        RETAILER_ID: 'r-m.retailer_id',
        RETAILER_NAME: 'r-m.retailer_name',
        RETAILER_CITY: 'r-m.retailer_city',
        RETAILER_URL: 'r-m.retailer_url',
        ACCEPTED_MARKET: 'r-m.accepted_market',

        PERFORMANCE: 'rm.cookies_performance_accepted',
        FUNCTIONAL: 'rm.cookies_functional_accepted',
        ADVERTISING: 'rm.cookies_advertising_accepted',

        SETTINGS: 'rm.custom_cookie_settings'
    },

    // URL Params
    URL_PARAM_RETAILER: 'retailer',

    PAGE_TYPES: {
        DEFAULT: 'page-default',
        //
        HOME: 'page-home',
        CATEGORY: 'page-category',
        SERIES: 'page-series',
        BIKE: 'page-bike',
        RETAILER_SEARCH: 'page-retailer-search',
        SEARCH: 'page-search',
        NEWS: 'page-news',
        CONTACT: 'page-contact',
        CAREER: 'page-career',
        TESTS: 'page-tests',
        PRESS: 'page-press',
        FAQ: 'page-faq',
        SALE: 'page-sale',
        MYRM: 'page-myrm',
        //
        CONTENT: 'page-content',
        OTHER: 'page-other'
    },

    FILTER: {
        MODES: {
            AND: 'r-m.filter.mode.and',
            OR:  'r-m.filter.mode.or'
        }
    },

    GOOGLE_MAPS: {
        MARKER_IMAGES: {
            DEFAULT: '/static/assets/img/retailer-search/maps-marker-default.png',
            FEATURED_STORE: '/static/assets/img/retailer-search/maps-marker-testbike.png',
            MATCHING_TESTBIKE: '/static/assets/img/retailer-search/maps-marker-featured-store.png',
        },
    },
}

const Config = {
    // common life time of cookies in days, applicable for most cookies
    COMMON_COOKIE_LIFETIME: 365,

    GOOGLE_MAPS: {
        GOOGLE_MAPS_VERSION: 3.54,
        ZOOM_LEVEL: 12,
    },

    GOOGLE_MAPS_VERSION: 3.54,


    PARALLAX_FACTOR: 3,

    // in px
    THRESHOLD_HIDE_CTA_SCROLL: 100,

    // Prozent der windowHeight, wo die topbar eingeblendet / Menü + Logo verkleinert werden
    THRESHOLD_SHOW_TOP_BAR: 0.25,
    THRESHOLD_SHOW_TOP_BAR_PX: 40,

    // Prozent der windowHeight, die nach oben / unten angeschnitten sein müssen, um "Modul in Viewport" zu tracken
    THRESHOLD_MODULE_IN_VIEWPORT_TOP: 0.4,
    THRESHOLD_MODULE_IN_VIEWPORT_BOTTOM: 0.5,

    // Wert in px: Wenn Wert zwischen zwei Scrollevents Threshold überschreitet, wird "schnelles Scrollen" erkannt
    THRESHOLD_SCROLL_FAST: 500,

    TIMEOUT_SCROLL: 250,
    TIMEOUT_RESIZE: 100,	// TODO - früher 10ms, besser 100?

    BACKDROP_OPACITY: 0.8,

    SHOW_MAP_INITIALLY: [
        'en-us',
        'fr-ca',
        'en-ca',
        'en-nz',
        'en-au',
        'en-no',
        'en-se',
        'en-fi',
        'es-es',
    ],
}

const Translations = {
    DATEPICKER: {
        TRANS: {
            'de-DE': {
                format: 'dd.mm.yyyy',
                monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                daysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                weekStart: 1
            },
            'es-ES': {
                format: 'dd.mm.yyyy',
                monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                daysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                weekStart: 1
            },
            'fr-FR': {
                format: 'dd.mm.yyyy',
                monthsShort: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juill', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
                daysMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
                weekStart: 1
            },
            'it-IT': {
                format: 'dd.mm.yyyy',
                monthsShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
                daysMin: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
                weekStart: 1
            },
            'nl-NL': {
                format: 'dd.mm.yyyy',
                monthsShort: ['Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
                daysMin: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vrij', 'Za'],
                weekStart: 1
            }
        },
        MAP_LOCALE: {
            'de': 'de-DE',
            'de-at': 'de-DE',
            'de-ch': 'de-DE',
            'es-es': 'es-ES',
            'fr': 'fr-FR',
            'fr-be': 'fr-FR',
            'fr-ca': 'fr-FR',
            'fr-ch': 'fr-FR',
            'it': 'it-IT',
            'it-ch': 'it-IT',
            'nl': 'nl-NL',
            'nl-be': 'nl-NL'
        }
    }
}

if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        Constants,
        Config,
        Translations,
    }
}
